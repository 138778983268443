// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { ProductModel } from '../_models/product.model';
import { environment } from '../../../../environments/environment';
const API_PRODUCTS_URL = environment.API_URL
// Real REST API
@Injectable()
export class ProductsService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient,
		           private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new product to the server
	createProduct(product): Observable<ProductModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<ProductModel>(API_PRODUCTS_URL, product, { headers: httpHeaders });
	}

	// READ
	getAllProducts(): Observable<ProductModel[]> {
		return this.http.get<ProductModel[]>(API_PRODUCTS_URL);
	}

	getProductById(productId: number): Observable<ProductModel> {
		//return this.http.get<ProductModel>(API_PRODUCTS_URL + `/${productId}`);

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const httpParams = {"userId": `${productId}`}

		const url = API_PRODUCTS_URL + 'user/get';
		return this.http.post<ProductModel>(url , httpParams , httpOptions)
	}

	// Server should return filtered/sorted result
	findProducts(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
		  };
		const httpParams = queryParams

		const url = API_PRODUCTS_URL + 'user/admin/list';
		return this.http.post<QueryResultsModel>(url , httpParams , httpOptions)
	}

	// UPDATE => PUT: update the product on the server
	updateProduct(product: ProductModel): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_PRODUCTS_URL, product, { headers: httpHeaders });
	}

	updateStatusForProduct(id: string, status: boolean): Observable<any> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			userId: id,
			status: status
		};
		const url = API_PRODUCTS_URL + 'user/admin/updateStatus';
		return this.http.post(url, body, httpOptions);
	}
	updateUserType(id: string, userType: string): Observable<any> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			id: id,
			userType: userType
		};
		const url = API_PRODUCTS_URL + 'user/admin/update';
		return this.http.post(url, body, httpOptions);
	}

	// DELETE => delete the product from the server
	deleteProduct(productId: number): Observable<ProductModel> {
		const url = `${API_PRODUCTS_URL}/${productId}`;
		return this.http.delete<ProductModel>(url);
	}

	deleteProducts(ids: number[] = []): Observable<any> {
		const url = API_PRODUCTS_URL + '/delete';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { prdocutIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
